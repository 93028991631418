.mainContainer {
  display: flex;
  flex: none;
  height: 519px;
  flex-wrap: wrap;
  flex-direction: column;
  overflow: auto;
  white-space: nowrap;
  scroll-behavior: smooth;

  scroll-snap-type: x mandatory;

  margin-bottom: 23px;

  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.mainContainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.card {
  margin-right: 2px;
  margin-left: 22px;
  width: calc(100% - 60px);
  scroll-snap-align: center;

  @media (min-width: 640px) {
    max-width: 315px;
  }

  &__last {
    margin-right: 24px;
  }
}

.outterContainer {
  display: flex;
  flex-direction: column;

  //min-height: 100vh;
  min-height: calc(100vh - 125px);
}

.markerContainer {
  width: 100%;
  justify-content: center;
  display: flex;
  @media (min-width: 640px) {
    display: none;
  }
}

.specialPlaceholder {
  width: 24px;
  height: 519px;
}
