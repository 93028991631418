.container {
  position: relative;
  padding: 20px 20px 220px;

  @media (min-width: 960px) {
    padding: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.title {
  font-family: "Manrope-SemiBold";
  color: var(--color-white);
  font-size: 28px;
  margin-bottom: 24px;

  @media (min-width: 960px) {
    font-size: 48px;
    margin-bottom: 38px;
    padding: 0 var(--desktop-horizontal-padding);
    max-width: 1680px;
    width: 100%;
    align-self: center;
  }
}

.programContainer {
  display: flex;
}

.programWrapper {
  display: none;

  @media (min-width: 960px) {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 1680px;
  }

  &_mobile {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 960px) {
      display: none;
    }
  }
}
