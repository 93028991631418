.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Manrope-Medium";
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.04) 44.08%,
    rgba(255, 255, 255, 0.02) 122.15%
  );
  backdrop-filter: blur(38.65px);
  padding: 6px 15px;
  font-size: 13px;
  flex: 1;
  border-radius: 12px;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  white-space: nowrap;
  flex-grow: 0;

  @media (min-width: 960px) {
    padding: 8px 48px;
    font-size: 16px;
    flex: unset;
    border-radius: 20px;
    border: 1px solid var(--color-gray);
    color: var(--color-gray);
  }

  &:hover {
    border-color: var(--color-white);
    color: var(--color-white);
  }

  //&:active {
  //  background-color: var(--color-darkGreen);
  //  border-color: var(--color-darkGreen);
  //}

  &__green {
    background-color: var(--color-green);
    border-color: var(--color-green);
    color: var(--color-white);

    &:hover {
      border-color: var(--color-green);
    }
  }

  &__dark {
    @media (min-width: 960px) {
      border-color: rgba($color: #000000, $alpha: 0.5);
      color: rgba($color: #000000, $alpha: 0.5);
      padding: 8px 30px;
    }

    &__selected {
      color: var(--color-black);
      border-color: var(--color-white);
      background-color: var(--color-white);

      &:hover {
        color: var(--color-black);
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  gap: 12px;
  box-sizing: border-box;

  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  @media (min-width: 960px) {
    gap: 16px;
  }
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.specialPlaceholder {
  width: 12px;
  height: 32px;
  @media (min-width: 960px) {
    display: none;
  }
}
