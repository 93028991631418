.container {
  padding: 20px 20px 150px;
  margin-bottom: 80px;
  @media (min-width: 960px) {
    margin-bottom: 0;
    padding: unset;
  }

  min-width: 0;
}

.title {
  color: var(--color-white);
  font-family: Manrope-SemiBold;
  font-size: 40px;
  margin-bottom: 37px;

  @media (min-width: 960px) {
    margin-bottom: 60px;
  }
}

.item {
  display: flex;
  font-size: 24px;
  font-family: Manrope-SemiBold;
  padding: 20px 0;
  color: var(--color-white);
  &__icon {
    margin-right: 24px;
  }
}
