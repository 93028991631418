.container{

  @media (min-width: 960px) {
    width: 306px;
  }
  //height: 410px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  aspect-ratio: 3 / 4;

  flex-shrink: 0;
}

.avatar{
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform-origin: 50% 20%;
  transition: transform 0.6s;
}

.container:hover .avatar{
  transform: scale(120%);
}

.avatarOverlay{
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 140px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.63) 100%);
  transition: height 0.6s;
}

.container:hover .avatarOverlay{
  height: 197px;
}

.content{
  flex:1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 18px 23px;
}

.logo{
  align-self: end;
  width: 60px;
  height: 50px;
  opacity: 0;
  transition: opacity 0.4s;
}

.container:hover .logo{
  opacity: 1;
}

.textContainer{
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.name{
  font-family: 'Manrope-Bold';

  color: var(--color-white);
  transform: translateY(0px);
  margin-bottom: -20px;
  transition: transform 0.6s;

  font-size: 16px;
  @media (min-width: 960px) {
    font-size: 24px;
  }
}

.container:hover .name{
  transform: translateY(-24px)!important;
}

.duty{
  font-family: 'Manrope-Regular';
  font-size: 12px;
  @media (min-width: 960px) {
    font-size: 16px;
  }
  color: var(--color-white);
  opacity: 0;
  transition: opacity 0.4s;
}

.container:hover .duty{
  opacity: 1;
}
