.term {
  border-bottom: 1px solid var(--color-white);
  padding-bottom: 16px;
  padding-top: 24px;

  @media (min-width: 960px) {
    display: flex;
    padding-bottom: 24px;
  }

  .title {
    font-family: "Manrope-Regular";
    color: var(--color-green);
    font-size: 20px;

    margin: 0;
    margin-bottom: 16px;

    @media (min-width: 960px) {
      font-size: 24px;
      width: 330px;
      margin-bottom: 0;
      padding-right: 16px;
    }
  }

  .description {
    font-family: "Manrope-Bold";
    color: var(--color-white);
    font-size: 16px;
    line-height: 153%;

    margin: 0;

    &__margin {
      margin-bottom: 15px;
    }

    &__light {
      font-family: "Manrope-Regular";
      max-width: 745px;
    }
  }
}

.descriptionContainer {
  white-space: pre-wrap;
}

.container {
  display: none;

  @media (min-width: 960px) {
    display: flex;
    overflow: hidden;
    position: relative;
  }
}

.wrapper {
  min-width: 100vw;
  display: flex;
  justify-content: center;
}

.slide {
  width: 100%;
  height: 100vh;
  padding: 0 24px;

  @media (min-width: 960px) {
    max-width: 1680px;
    padding: 0 var(--desktop-horizontal-padding) 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.pageMarkerContainer {
}

.pageMarkerWrapper {
  display: none;
  @media (min-width: 960px) {
    display: flex;

    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    transition: all .5s;
  }
}

.mobileContainer {
  padding: 0 20px 220px;

  @media (min-width: 960px) {
    display: none;
  }
}
