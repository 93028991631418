.collapsible {
  cursor: pointer;
  color: var(--color-black);
  position: relative;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: 960px) {
    margin-bottom: 24px;
  }

  .title {
    font-family: "Manrope-ExtraBold";
    font-size: 14px;
  }
}

.closeButton {
  position: absolute;
  z-index: 100;
  right: 3px;
  top: 8px;
}

.content {
  overflow: hidden;
  transition: height 0.3s;

  &__text {
    font-family: "Manrope-Regular";
    font-size: 14px;

    white-space: pre-wrap;
    padding-top: 20px;

    @media (min-width: 960px) {
      font-family: "Manrope-Medium";
      padding-top: 24px;
    }
  }
}
