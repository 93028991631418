.outterContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 24px 200px;

  @media (min-width: 960px) {
    padding: 0 0 0px;
  }

  .title {
    font-family: "Manrope-SemiBold";
    color: var(--color-white);

    font-size: 10vw;
    margin: 0 0 24px;

    @media (min-width: 960px) {
      font-size: 42px;
      margin: 0 0 94px;
    }
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 960px) {
      flex-direction: row;
    }

    .leftContainer {
      width: 100%;
      margin-bottom: 24px;
      @media (min-width: 960px) {
        width: 50%;
        margin-bottom: unset;
      }

      .slider {
        margin-bottom: 16px;
        @media (min-width: 960px) {
          margin-bottom: 40px;
        }
      }
    }

    .rightContainer {
      width: 100%;
      margin-top: 40px;
      @media (min-width: 960px) {
        margin-top: unset;
        width: 50%;
      }

      .monthlyPayment {
        margin-bottom: 27px;
        display: flex;
        flex-direction: column-reverse;

        @media (min-width: 960px) {
          margin-bottom: 70px;
          flex-direction: column;
        }

        .calc {
          display: flex;
          align-items: center;

          max-width: unset;
          margin-top: 20px;

          @media (min-width: 960px) {
            max-width: 300px;
            margin-bottom: 28px;
            margin-top: unset;
          }

          .monthlyPaymentSumm {
            font-family: "Manrope-Medium";

            color: var(--color-white);
            line-height: 40px;

            margin: 0 auto;
            font-size: 40px;

            @media (min-width: 960px) {
              margin: 0 auto;
              font-size: 38px;
              font-family: "Manrope-SemiBold";
              line-height: 38px;
            }
          }
        }
      }

      .smallText {
        font-family: "Manrope-Regular";
        font-size: 14px;
        margin: 0;
        color: var(--color-white);

        @media (min-width: 960px) {
          font-size: 16px;
        }

        &__title {
          font-size: 16px;
        }
      }

      .calculatedSumms {
        margin-bottom: 30px;
        @media (min-width: 960px) {
          display: flex;
          margin-bottom: 53px;
        }
      }

      .buttonContainer {
        display: flex;
        flex-direction: column-reverse;
        @media (min-width: 960px) {
          display: block;
        }

        .button {
          border-radius: 12px;
          font-size: 20px;
          margin-bottom: 16px;
          width: 100%;
          height: 50px;

          @media (min-width: 960px) {
            width: unset;
            border-radius: 9px;
            font-size: 15px;
            margin-bottom: 38px;
            height: unset;
          }
        }
      }
    }
  }
}

.bottomText {
  font-family: "Manrope-Regular";
  font-size: 12px;
  color: var(--color-white);
  max-width: 420px;

  margin-bottom: 20px;

  @media (min-width: 960px) {
    font-size: 16px;
    max-width: 520px;

    margin-bottom: unset;
  }
}

.summaryContainer {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 20px;
  max-width: 200px;

  @media (min-width: 960px) {
    flex-direction: column;
    margin-bottom: unset;
    max-width: unset;
    margin-right: 48px;
  }

  &__right {
    margin-right: 0;
  }

  .summaryTop {
    display: flex;

    @media (min-width: 960px) {
      margin-bottom: 6px;
    }

    .summ {
      font-family: "Manrope-SemiBold";
      font-size: 24px;
      margin: 0;
      color: var(--color-white);

      margin-right: auto;

      @media (min-width: 960px) {
        margin-right: 8px;
        font-size: 25px;
      }
    }

    .iconContainer {
      .icon {
        height: 24px;
        align-self: center;
        cursor: pointer;

        @media (min-width: 960px) {
          height: unset;
        }
      }

      .iconActive {
        height: 24px;
        align-self: center;
        cursor: pointer;
        display: none;

        @media (min-width: 960px) {
          height: unset;
        }
      }
    }

    .iconContainer:hover .icon {
      display: none;
    }

    .iconContainer:hover .iconActive {
      display: block;
    }
  }

  .summaryInfoContainer {
    display: none;

    @media (min-width: 960px) {
      display: block;
      position: absolute;
      //margin-top: 20px;
      top: 70px;

      padding: 14px;
      border-radius: 9px;
      background-color: var(--color-white);
      width: 220px;
      white-space: pre-wrap;

      font-family: "Manrope-Regular";
      font-size: 12px;
    }
    @media (min-width: 1280px) {
      font-size: 14px;
      width: 260px;
    }

    &__hidden {
      display: none;
    }
  }
}

.delimiter {
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);

  @media (min-width: 960px) {
    display: none;
  }
}
