.mobileBlockContainer {
  position: relative;
}

.mobileBlockWrapper {
  z-index: 2;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  transform: translateZ(0);
  will-change: transform;
  position: absolute;
  overflow: hidden;

  //min-height: 100vh;

  //overflow: hidden;
  //position: sticky;
  //top: 124px;
  //height: 100vh;

  //margin-bottom: 1px;
}

.hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.mobileBlockWrapper_firstBlock {
  z-index: 1;
  width: 100%;
  //min-height: 100vh;
  position: absolute;
  overflow: hidden;
  //position: sticky;
  //top: 0;
  //height: 100vh;

  //margin-bottom: 1px;

  &::-webkit-scrollbar {
    display: none;
  }
}
