.itemTitle {
  font-family: "Manrope-SemiBold";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  opacity: 0.6;

  margin: 0;
  margin-right: 16px;
  width: 235px;
  min-width: 235px;
}

.modal {
  width: 674px;
}

.itemInfo {
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  margin: 0;
  max-width: 330px;
  overflow-wrap: break-word;

  & a {
    text-decoration: none;
    color: var(--color-black);
    &:hover {
      color: var(--color-green);
    }
  }
}

.itemIcon {
  margin-right: 12px;
}

.itemContainer {
  display: flex;
  padding: 20px 0 8px;

  &__first {
    border: 0;
  }
}

.bankButton {
  margin-top: 9px;
}

.headerButton {
  background-color: rgba(255, 255, 255, 0.2);
  font-family: "Manrope-Medium";
  font-size: 15px;
  color: var(--color-white);
  padding: 0 22px;
  margin-left: auto;
  height: 32px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &__icon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}
