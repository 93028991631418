.container {
  width: 100%;
  padding: 30px 20px 240px;

  @media (min-width: 960px) {
    padding: 0 20px 240px;
    padding: unset;
  }

}

.title {
  color: var(--color-white);
  font-size: 40px;
  font-family: 'Manrope-SemiBold';
  margin-bottom: 40px;
  @media (min-width: 960px) {
    margin-bottom: 4px;
  }
}

.subtitle {

  display: none;
  @media (min-width: 960px) {
    display: block;
  }

  color: rgba(255, 255, 255, 0.4);
  font-size: 24px;
  font-family: 'Manrope-SemiBold';
  margin-bottom: 85px;
}

.itemContainer {
  display: flex;
  flex-direction: column;

  gap: 32px;

  @media (min-width: 960px) {
    flex-direction: row;
    gap: unset;
    justify-content: space-between;
  }
}

.animatedItem{
  display: flex;
  //flex:1;
}

.item {
  max-width: 500px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 960px) {
    gap: 28px;
  }


  &__icon {
    width: 54px;
    height: 54px;
  }

  &__title {
    font-size: 32px;
    color: var(--color-white);
  }

  &__text {
    flex: 1;
    font-size: 14px;
    font-family: 'Manrope-Medium';
    color: var(--color-white);
  }

  &__delimiter {
    align-self: stretch;
    background-color: var(--color-white);

    height: 1px;
    min-height: 1px;

    @media (min-width: 960px) {
      width: 1px;
      min-width: 1px;
      margin: 48px 24px 0;

      height: auto;
    }
  }

}
