.titleText {
  color: #fff;
  font-family: "Manrope-SemiBold";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  text-decoration-line: none;
  cursor: default;

  margin: 0 auto 0 0;

  @media (min-width: 960px) {
    font-size: 20px;
    margin-right: 20px;
  }

  &__pdf {
    @media (min-width: 960px) {
      text-decoration-line: underline;
      text-decoration-thickness: 1px;

      cursor: pointer;
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  align-items: center;

  border-radius: 16px;
  background: rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 1120px;
  @media (min-width: 960px) {
    padding: 20px 24px 20px 24px;
    width: 100%;
    max-width: unset;
  }
}

.icon {
  margin-right: 12px;
  @media (min-width: 960px) {
    margin-right: 24px;
  }
}

.headerButtonContainer {
  display: none;

  @media (min-width: 960px) {
    display: flex;
    margin-left: auto;
  }
}

.headerButton {
  font-size: 16px;
  font-family: "Manrope-Medium";
  padding: 4px 16px;

  &__icon {
    height: 24px;
    margin-right: 8px;
  }
}
.smallButton {
  margin-left: 4px;
  @media (min-width: 960px) {
    display: none;
  }
}
