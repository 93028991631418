.content {
  width: 100%;

  &__last {
    margin-bottom: 30px;
  }
}

.button {
  padding: 11px 34px;

  &__small {
    font-size: 16px;

    &__right {
      flex-direction: row-reverse;
      background-color: rgba($color: #000000, $alpha: 0.2);
      padding: 8px 12px;
      width: 240px;
      min-width: 240px;

      margin-left: 12px;

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
    }

    .icon {
      margin-left: 8px;
      height: 22px;
    }
  }

  &__error {
    background-color: #d46e6e;

    &:hover {
      background-color: rgba($color: #ff0000, $alpha: 0.3);
    }
  }
}

.modal {
  height: 670px;
  width: 670px;

  @media (max-height: 700px) {
    height: 80vh;
  }
}

.title {
  //font-size: 23px;
  font-family: "Manrope-SemiBold";
}

.smallDescription {
  font-family: "Manrope-SemiBold";
  font-size: 14px;
  color: rgba($color: #000000, $alpha: 0.5);

  margin: 10px 0 20px 0;
}

.tabs {
  margin-bottom: 34px;

  &__lessMargin {
    margin-bottom: 19px;
  }
}
