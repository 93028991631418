.mainContainer {
  width: 100%;

  padding: 40px 15px 180px;

  @media (min-width: 960px) {
    display: flex;
    padding: 0 0 0;
  }
}

.leftContainer {
  width: 50%;
  @media (min-width: 960px) {
    width: 50%;
  }
}

.rightContainer {
  width: 50%;
  @media (min-width: 960px) {
    width: 50%;
  }
}
