.titleText {
  font-family: "Manrope-Medium";

  font-style: normal;
  line-height: normal;
  color: var(--color-black);

  font-size: 14px;

  @media (min-width: 960px) {
    font-size: 18px;
  }

  @media (min-width: 1640px) {
    font-size: 20px;
  }

}

.descriptionText {
  color: var(--color-textGray);
  font-family: "Manrope-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  margin: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.icon{
  align-self: flex-start;
  height: 36px;
  margin-bottom: 24px;
}
