.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid;
  cursor: pointer;

  &__30 {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  &__40 {
    width: 40px;
    height: 40px;
    border-radius: 22px;
  }

  &__blur {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 44.08%,
      rgba(255, 255, 255, 0) 122.15%
    );
    backdrop-filter: blur(10px);
  }

  &__white {
    border-color: var(--color-white);
  }
  &__black {
    border-color: var(--color-black);
  }
  &__calc {
    border-color: rgba(255, 255, 255, 0.3);

    &:hover {
      border-color: var(--color-white);
    }
  }
  &__disabled {
    opacity: 0.5;
  }
}
