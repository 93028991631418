.container {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 588px;
  position: relative;

  width: 100%;

  background: linear-gradient(
    270deg,
    rgba(46, 158, 137, 0.5) 0.05%,
    rgba(32, 118, 102, 0.22) 98.25%
  );
  /* bluer */
  backdrop-filter: blur(38.65px);
}

.image {
  height: 254px;
  border-radius: 15px;
  object-fit: cover;
  object-position: top;
}

.titleText {
  font-family: "Manrope-Bold";
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  color: var(--color-black);

  margin: 0 0 20px;
}

.lowerContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 24px 32px 24px;
}

.descriptionText {
  color: var(--color-black);
  font-family: "Manrope-SemiBold";
  font-size: 16px;
  font-style: normal;
  line-height: 138%;

  margin: 0;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: var(--color-white);
  opacity: 0.2;

  &__active {
    opacity: 1;
  }

  &__notAlone {
    margin-right: 8px;
  }
}

.dotsContainer {
  display: flex;
}

.switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;

  position: absolute;
  top: 194px;
  left: 12px;
  right: 12px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 44.08%,
    rgba(255, 255, 255, 0) 122.15%
  );
  backdrop-filter: blur(10px);
}

.arrow {
  cursor: pointer;
}

.arrowRight {
  transform: rotate(180deg);
}
