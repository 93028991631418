.container {
  display: none;

  @media (min-width: 960px) {
    display: flex;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
  }
}

.wrapper {
  min-width: 100vw;
  display: flex;
  justify-content: center;
}

.slide {
  width: 100%;
  min-height: 100vh;
  padding: 0 24px;

  @media (min-width: 960px) {
    max-width: 1680px;
    padding: 10px var(--desktop-horizontal-padding) 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.pageMarkerContainer {
}

.pageMarkerWrapper {
  display: none;
  @media (min-width: 960px) {
    display: flex;

    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    transition: all .5s;
  }
}

.mobileContainer {
  padding: 0 20px 180px;

  @media (min-width: 960px) {
    display: none;
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  @media (min-width: 960px) {
    overflow-y: unset;
  }
  height: 100%;
  padding-bottom: 50px;
}

.mobileContainer {
  padding: 32px 20px 220px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 960px) {
    display: none;
  }
}
