.container {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 25% 20px;
  width: 100%;
  height: 100%;

  border: 1px solid var(--White-hover, #fff);
  border-bottom: none;

  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #8c8c8c;

  &__green {
    background: linear-gradient(#54dbc2 90%, transparent);
    position: relative;
    padding-bottom: 0;
  }
}

.bottomGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 259px;
  background: linear-gradient(#54dbc2 0%, #00ffd0 100%);
}

.scrollContainer {
  overflow: scroll;
  z-index: 2;
  padding-top: 38px;
  padding-bottom: 40px;
}

.title {
  font-family: "Manrope-Bold";
  font-size: 28px;
  font-style: normal;
  line-height: normal;
  margin: 0;
  color: var(--color-black);
  margin-bottom: 24px;
}

.button {
  display: flex;
  padding: 16px 8px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  position: relative;

  background: linear-gradient(
                  90deg,
                  rgba(86, 86, 86, 0.4) -1.66%,
                  rgba(0, 0, 0, 0) 177.08%
  );
  backdrop-filter: blur(11.100000381469727px);

  &__zeroMargin {
    margin-bottom: 0;
  }

  &:hover {
    background: linear-gradient(
                    90deg,
                    rgba(86, 86, 86, 0.4) -1.66%,
                    rgba(0, 0, 0, 0) 177.08%
    );
    backdrop-filter: blur(11.100000381469727px);
  }
}

.buttonText {
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  margin: 0;
  color: var(--color-white);
}

.closeButton {
  position: absolute;
  right: 8px;
}
