.topTitle {
  margin: 20px 20px 0 20px;
}

.scrollContainer {
  padding: 0 0 0 0;
}

.tabs {
  margin-bottom: 12px;
  margin-top: 10px;
  padding: 0 20px;
}

.picker {
  margin: 0 20px 16px 20px;
}

.container {
  padding: 0 20px 25vh 20px;
}

.terms {
  font-family: "Manrope-Regular";
  font-size: 12px;
  color: rgba($color: #000000, $alpha: 0.5);

  margin-top: 12px;
  margin-bottom: 49px;
  cursor: pointer;

  &__input {
    margin-bottom: 0;
    cursor: default;
  }
}

.button {
  font-size: 18px;
  height: 65px;
  width: 100%;
  padding: 12px 20px;

  &__upper {
    margin-bottom: 10px;
  }

  &__error {
    background-color: rgba($color: #ff0000, $alpha: 0.4);
  }
}

.error {
  color: var(--color-errorRed);
}
