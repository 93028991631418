.container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  align-items: center;
  justify-content: center;
  display: flex;
}

.wrapper{
  transition: opacity 0.3s;
  opacity: 0;
  &_open {
    opacity: 1;
  }
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  transition: backdrop-filter 0.3s, background 0.3s;

  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.0) 21.99%, rgba(0, 0, 0, 0.0) 72.92%, rgba(0, 0, 0, 0.00) 99.94%);
  backdrop-filter: blur(23.4px) opacity(0);

  &_open {
    backdrop-filter: blur(23.4px) opacity(1);
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.61) 21.99%, rgba(0, 0, 0, 0.68) 72.92%, rgba(0, 0, 0, 0.00) 99.94%);
  }

}
