.container{
  position: relative;
  width: 34px;
  height: 51px;
  border-radius: 28px;
  border: 2px solid rgba(255,255,255,0.2);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.rectangle{
  position: absolute;
  top:8px;
}

.firstArrow{
  opacity: 0;
  position: absolute;
  top:18px;
  animation: arrow1 1s linear infinite;
}

.secondArrow{
  opacity: 0;
  position: absolute;
  top:24px;
  animation: arrow2 1s linear infinite;
}

.thirdArrow{
  opacity: 0;
  position: absolute;
  top:30px;
  animation: arrow3 1s linear infinite;
}

@keyframes arrow1 {
  0%{opacity: 0;}
  30% {opacity: 1}
  60% {opacity: 0}
}

@keyframes arrow2 {
  10%{opacity: 0}
  40% {opacity: 1}
  70% {opacity: 0}
}
@keyframes arrow3 {
  20%{opacity: 0;}
  50% {opacity: 1}
  80% {opacity: 0}
}
