.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  line-height: normal;

  &__light {
    border: 1px solid;
    border-color: var(--color-buttonGray);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-green);
      border-color: var(--color-green);
    }

    &:active {
      background-color: var(--color-darkGreen);
      border-color: var(--color-darkGreen);
    }
  }

  &__gray {
    border: 1px solid;
    border-color: var(--color-buttonGray);
    color: var(--color-white);

    &:hover {
      border-color: var(--color-buttonHoverGray);
    }
  }

  &__grayFilled {
    background-color: var(--color-buttonGray);
    color: var(--color-white);

    &:hover {
      border-color: var(--color-buttonHoverGray);
    }
  }

  &__black {
    background-color: var(--color-black);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-textDarkDarkGray);
    }
  }

  &__blackStroke {
    border: 2px solid;
    border-color: var(--color-black);
    color: var(--color-black);
  }

  &__green {
    background-color: var(--color-green);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-darkGreen);
    }
  }

  &__greenInverted {
    background-color: var(--color-white);
    color: var(--color-green);

    &:hover {
      color: var(--color-darkGreen);
    }
  }

  &__white {
    //background-color: transparent;
    border: 1px solid var(--color-white);
    color: var(--color-white);

    &:hover {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.21) 44.08%,
        rgba(255, 255, 255, 0.16) 122.15%
      );
    }
  }

  &__grayBlurred {
    border: 1px solid rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.6);

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.04) 44.08%,
      rgba(255, 255, 255, 0.02) 122.15%
    );
    backdrop-filter: blur(38.65px);

    &:hover {
      color: rgba(0, 0, 0, 0.6);
      background: rgba(255, 255, 255, 0.4);
    }
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__small {
    width: 100px;
    height: 24px;
    border-radius: 8px;

    font-family: "Manrope-Regular";
    font-size: 11px;
  }

  &__average {
    padding: 8px 16px;
    font-size: 14px;
    font-family: "Manrope-SemiBold";
    border-radius: 12px;
  }

  &__medium {
    padding: 8px 36px;
    border-radius: 10px;

    font-family: "Manrope-SemiBold";
    font-size: 12px;
  }

  &__big {
    padding: 12px 60px;
    border-radius: 15px;

    font-family: "Manrope-SemiBold";
    font-size: 20px;
  }

  &__mobile {
    @media (max-width: 960px) {
      padding: 15px 10px;
    }
  }
}
