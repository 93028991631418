@font-face {
  font-family: "Manrope-ExtraBold";
  src: local("Manrope-ExtraBold.ttf"), url("./manrope/Manrope-ExtraBold.ttf");
}

@font-face {
  font-family: "Manrope-Bold";
  src: local("Manrope-Bold.ttf"), url("./manrope/Manrope-Bold.ttf");
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: local("Manrope-SemiBold.ttf"), url("./manrope/Manrope-SemiBold.ttf");
}

@font-face {
  font-family: "Manrope-Medium";
  src: local("Manrope-Medium.ttf"), url("./manrope/Manrope-Medium.ttf");
}

@font-face {
  font-family: "Manrope-Regular";
  src: local("Manrope-Regular.ttf"), url("./manrope/Manrope-Regular.ttf");
}

@font-face {
  font-family: "Manrope-Light";
  src: local("Manrope-Light.ttf"), url("./manrope/Manrope-Light.ttf");
}

@font-face {
  font-family: "Manrope-ExtraLight";
  src: local("Manrope-ExtraLight.ttf"), url("./manrope/Manrope-ExtraLight.ttf");
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: local("Inter-ExtraBold.ttf"), url("./inter/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold.ttf"), url("./inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold.ttf"), url("./inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium.ttf"), url("./inter/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular.ttf"), url("./inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-Light";
  src: local("Inter-Light.ttf"), url("./inter/Inter-Light.ttf");
}

@font-face {
  font-family: "Inter-ExtraLight";
  src: local("Inter-ExtraLight.ttf"), url("./inter/Inter-ExtraLight.ttf");
}
