.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 960px) {
    width: 440px;
  }

  .value {
    font-family: "Manrope-Medium";
    font-size: 32px;
    color: var(--color-white);
    margin: 0 0 12px;

    @media (min-width: 960px) {
      font-size: 38px;
      margin: 0 0 7px;
    }

    &__error {
      color: #e03e1a;
    }
    * div {
      font-size: 32px !important;
      @media (min-width: 960px) {
        font-size: 38px !important;
      }
      padding-right: 6px;
    }
    * input {
      font-family: "Manrope-Medium";
      font-size: 32px;
      color: var(--color-white);
      margin: 0 0 12px;
      background: transparent;
      border: unset;
      padding: 0;

      @media (min-width: 960px) {
        font-size: 38px;
        margin: 0 0 7px;
      }

      &__error {
        color: #e03e1a;
      }
    }
  }

  .slider {
    height: 10px;
    margin-bottom: 12px;
    @media (min-width: 960px) {
      margin-bottom: 9px;
    }

    .track {
      cursor: pointer;
      height: 2px;
      background-color: var(--color-green);
      border-radius: 1px;
      top: 4px;
      &__white {
        background-color: rgba($color: #ffffff, $alpha: 0.3);
      }
    }

    .thumb {
      cursor: pointer;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--color-green);

      &:focus {
        outline: none;
      }
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: 960px) {
      padding-right: 54px;
    }

    .bottomText {
      font-family: "Manrope-Regular";
      font-size: 14px;
      @media (min-width: 960px) {
        font-size: 16px;
      }
      margin: 0;
      color: var(--color-white);
    }
  }
}
