.screenMobile {
  padding-top: 52px;
  padding-bottom: var(--desktop-horizontal-padding);
  padding-bottom: 200px;
  width: 100%;

  @media (min-width: 960px) {
    display: none;
  }

  @media (min-height: 700px) {
    padding-bottom: 0;
    min-height: unset;
  }
}

.screen {
  display: none;
  width: 100%;

  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }

  &__scroll {
    @media (min-width: 960px) {
      //max-height: 100%;
      padding-top: 130px;
    }
  }

  .arrowsContainer {
    display: flex;
    justify-content: flex-end;
    padding-right: var(--desktop-horizontal-padding);
    margin-bottom: 37px;

    .arrowLeft {
      margin-right: 12px;
    }
  }

  .hide {
    display: none;
  }
}

.title {
  font-family: "Manrope-SemiBold";
  font-size: 42px;
  line-height: 37.5px;
  color: var(--color-white);
  margin-bottom: 50px;

  padding: 0 var(--desktop-horizontal-padding);
  width: 100%;
  max-width: 1680px;
}

.programWrapper {
  @media (min-width: 960px) {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 1680px;
  }
}
