.mainContainer {
  padding: 20px 30px 220px;
  width: 100%;

  @media (min-width: 960px) {
    display: flex;
    padding: 24px 30px 100px 0;
    justify-content: space-between;
  }

  .title {
    font-family: "Manrope-Regular";
    color: var(--color-green);
    font-size: 20px;

    margin: 0;
    margin-bottom: 24px;

    @media (min-width: 960px) {
      font-size: 24px;
    }
  }

  .column {
    @media (min-width: 960px) {
      max-width: 30vw;
      margin-left: 20px;
    }
  }

  ul {
    margin: 0;
    padding-inline-start: 20px;
  }

  .description {
    font-family: "Manrope-Bold";
    color: var(--color-white);
    font-size: 16px;
    line-height: 22px;

    margin: 0;
    margin-bottom: 8px;
  }
}
