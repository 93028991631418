.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;

  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.73) 100%);

  @media (min-width: 960px) {
    border-radius: 16px;
    padding: 30px;
  }
}
