.mainContainer {
  padding: 0;
  height: 260px;
  width: 100%;

  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  border-radius: 15px;

  @media (min-width: 640px) {
    min-width: 218px;
    width: 218px;
  }

  @media (min-width: 960px) {
    width: 235px;
    flex-shrink: 0;
  }

  @media (min-width: 1440px) {
    width: 265px;
  }

  @media (min-width: 1920px) {
    width: 306px;
  }
}

.mainContainer:hover .button {
  background-color: var(--color-black);
}

.discount {
  font-family: "Manrope-Bold";
  font-size: 25px;
  font-style: normal;
  line-height: 22px;
  color: var(--color-green);

  margin: 0;
  margin-bottom: 24px;
}

.descriptionText {
  font-family: "Manrope-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  margin: 0;
  margin-bottom: auto;

  &__top {
    margin-bottom: 10px;
  }

  @media (min-width: 640px) {
    font-size: 12px;
    line-height: normal;
  }
}

.topContainer {
  border-bottom: 1px solid #8a9199;
  padding: 24px;
  padding-bottom: 0;
  height: 82px;

  font-family: "Manrope-Bold";
  font-size: 24px;
  line-height: 24px;
  overflow-wrap: break-word;

  @media (min-width: 640px) {
    font-size: 18px;
    line-height: 22px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 24px 24px 24px;
}
