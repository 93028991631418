.header__text{
  white-space: nowrap;
  font-family: 'Manrope-SemiBold';
  font-size: 16px;
  color: var(--color-white);

  &_black{
    color: var(--color-black);
  }

  &__clickable{
    opacity: 0.5;
    cursor: pointer;

    &:hover{
      opacity: 1;
    }
  }

  &__slash{
    padding: 0 4px;
    opacity: 0.5;
  }
}
