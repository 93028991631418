.outterContainer {
  position: relative;

  @media (min-width: 960px) {
    width: fit-content;
  }

  .container {
    border-radius: 40px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-white);
    cursor: pointer;

    scrollbar-width: thin;
    scrollbar-color: #d9d9d9;

    @media (min-width: 960px) {
      border: 1px solid rgba($color: #ffffff, $alpha: 0.6);
      border-radius: 12px;
      height: 35px;
      width: 35px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.04) 44.08%,
        rgba(255, 255, 255, 0.02) 122.15%
      );
      backdrop-filter: blur(38.65px);

      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }

    &__large {
      width: 100%;
      justify-content: space-between;
      padding-right: 8px;

      @media (min-width: 960px) {
        width: 180px;
        justify-content: flex-end;
      }
    }

    &__focused {
      justify-content: flex-end;

      @media (min-width: 960px) {
        background-color: rgba($color: #ffffff, $alpha: 0.5);
      }
    }

    .title {
      font-family: "Manrope-Regular";
      font-size: 13px;
      color: #333333;
      margin: 0;

      margin-left: 10px;

      @media (min-width: 960px) {
        font-family: "Manrope-SemiBold";
        font-size: 14px;
        color: rgba($color: #ffffff, $alpha: 0.6);

        margin-right: 10px;
        margin-left: 0;
      }
    }

    .input {
      font-family: "Manrope-SemiBold";
      font-size: 14px;
      color: rgba($color: #000000, $alpha: 0.6);

      height: 100%;
      border-width: 0;
      padding: 0 35px 0 10px;
      background-color: transparent;

      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .container:focus-within {
    @media (min-width: 960px) {
      background-color: rgba($color: #ffffff, $alpha: 0.5);
    }
  }

  .dropdownContainer {
    border-radius: 10px;
    height: 128px;
    width: 100%;
    padding: 11px 0 12px 0;
    overflow-y: auto;
    overflow-x: hidden;

    position: absolute;
    top: 45px;

    background: var(--color-white);

    @media (min-width: 960px) {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.04) 44.08%,
        rgba(255, 255, 255, 0.02) 122.15%
      );
      backdrop-filter: blur(38.65px);
      border: 1px solid rgba($color: #ffffff, $alpha: 0.6);

      padding: 10px 0 10px 0;
      height: 122px;
    }

    &__small {
      height: 60px;
      padding: 11px 10px;

      @media (min-width: 960px) {
        height: 57px;
        padding: 10px 10px 0 11px;
      }
    }

    .itemTitle {
      font-family: "Manrope-SemiBold";
      font-size: 12px;
      color: rgba($color: #282828, $alpha: 0.6);
      text-transform: capitalize;
      margin: 0;

      @media (min-width: 960px) {
        font-size: 10px;
        color: rgba($color: #ffffff, $alpha: 0.6);
      }
    }

    .itemDescription {
      font-family: "Manrope-SemiBold";
      font-size: 8px;
      color: rgba($color: #000000, $alpha: 0.6);

      margin: 0;

      @media (min-width: 960px) {
        color: rgba($color: #cccccc, $alpha: 0.6);
      }
    }

    .listItem {
      padding: 4px 0 5px 10px;
      display: block;
      text-decoration: none;

      @media (min-width: 960px) {
        padding: 2px 0 4px 10px;
      }

      &:hover {
        background-color: rgba($color: #d9d9d9, $alpha: 0.3);
      }
    }
  }
}

.dropdownContainer::-webkit-scrollbar {
  width: 2px;
}

.dropdownContainer::-webkit-scrollbar-track {
  margin-top: 5px;
  margin-bottom: 5px;
}

.dropdownContainer::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 2px;
  width: 2px;
}
