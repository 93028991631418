.itemTitle {
  font-family: "Manrope-SemiBold";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  opacity: 0.6;

  margin: 0;
}

.itemInfo {
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-style: normal;
  line-height: normal;

  margin: 0;

  @media (min-width: 960px) {
    font-size: 18px;
  }
}

.modal {
  width: 674px;
  height: 605px;
  max-height: 90vh;
  overflow-y: scroll;
}

.itemIcon {
  margin-right: 12px;
  height: 16px;
}

.itemContainer {
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  &__first {
    border: 0;
  }
}
.buttonContainer {
  flex: 1;
  align-items: flex-end;
  display: flex;

  &__mobile {
    align-items: unset;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.button {
  width: 217px;
  height: 40px;
  padding: 0;

  font-family: "Manrope-SemiBold";
  font-size: 16px;

  &__left {
    margin-right: 12px;
  }

  &__top {
    margin-bottom: 8px;
  }
}

.button_mobile {
  height: 65px;
  font-size: 18px;
}

.itemScrollContainer {
  padding-top: 18px;
  overflow: scroll;
  height: 100%;
}

.contactItem {
  padding: 32px 0 20px;
}

.modalMobile {
  max-height: calc(100vh - 10px);
  height: 100%;
}
