.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 22px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;

  &__disabled {
    opacity: 0.5;
  }

  &__square {
    border-radius: 11px;
  }
}

.container:hover {
  border: 1px solid var(--color-white);
}
