.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.wrapper {
  height: 100%;
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  transform: translateY(100%);

  transition: transform 0.3s;

  &_open {
    transform: translateY(0);
  }
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0);

  transition: background-color 0.3s, background 0.3s;

  &_open {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
