.content {
  font-family: "Manrope-Regular";
  font-size: 20px;
  line-height: 120%;
  color: var(--color-textDarkDarkGray);
  min-width: 150px;
  white-space: pre-wrap;
  margin-bottom: 24px;

  &__heading {
    font-family: "Manrope-SemiBold";
  }

  &__title {
    margin-bottom: 24px;
  }
}

.bottomListContainer {
  display: flex;
  flex-direction: row;

  .button {
    background-color: var(--color-white);
    color: #463e3e;
    margin-right: 11px;
    font-size: 16px;
    border-radius: 15px;
    border-width: 0;
    height: 40px;

    &:hover {
      background-color: #c2c2c2;
      color: var(--color-black);
    }
  }
}
