.screenMobile {
  padding: 52px 20px 200px;
  width: 100%;

  @media (min-width: 640px) {
    padding: 52px 0 200px;
  }

  @media (min-width: 960px) {
    display: none;
  }

  .cardsContainerMobile {
    width: 100%;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */

    @media (min-width: 640px) {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      gap: 24px;
      padding: 0 20px;

      margin-bottom: 40px;
    }
  }

  .block {
    @media (min-width: 1440px) {
      justify-content: center !important;
    }
  }

  .titleMobile {
    font-family: "Manrope-SemiBold";
    font-size: 28px;
    color: var(--color-white);

    margin-bottom: 24px;

    @media (min-width: 640px) {
      padding-left: 20px;
    }
  }

  .card {
    margin-right: 0;
    margin-bottom: 24px;

    @media (min-width: 640px) {
      margin-bottom: 0;
    }
  }
}

.screen {
  display: none;

  @media (min-width: 960px) {
    display: block;
    width: 100%;
    z-index: 10;
  }

  @media (min-width: 1440px) {
    max-width: 1680px;
    align-self: center;
  }

  .arrowsContainer {
    display: flex;
    justify-content: flex-end;
    padding-right: 120px;
    margin-bottom: 37px;

    .arrowLeft {
      margin-right: 12px;
    }
  }

  .hide {
    display: none;
  }
}

.cardWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1680px;
}

.conditionsContainer {
  display: flex;
  padding: 0 var(--desktop-horizontal-padding);

  @media (min-width: 960px) {
    padding-top: 30px;
  }

  @media (min-width: 1440px) {
    padding-top: 40px;
  }

  @media (min-width: 1920px) {
    padding-top: 60px;
  }
}

.descriptionTitle {
  font-family: "Manrope-Regular";
  font-size: 20px;
  color: var(--color-green);

  margin-bottom: 20px;
  padding-top: 16px;

  @media (min-width: 640px) {
    padding: 0 20px;
  }

  @media (min-width: 960px) {
    min-width: 310px;
    margin-right: 24px;
    margin-bottom: 0;

    padding: 0;
  }
}

.descriptionContainer {
  @media (min-width: 640px) {
    padding: 0 20px;
    max-width: 600px;
  }

  @media (min-width: 960px) {
    padding: 0;
  }

  .description {
    font-family: "Manrope-Regular";
    font-size: 16px;
    color: var(--color-white);
    margin-bottom: 12px;

    &:hover {
      @media (min-width: 960px) {
        opacity: 1;
        color: var(--color-green);
      }
    }

    @media (min-width: 960px) {
      opacity: 0.8;
    }

    &__subtitle {
      font-family: "Manrope-SemiBold";
      opacity: 1;

      pointer-events: none;
    }

    &__green {
      color: var(--color-green);
      text-decoration: none;
    }
  }
}
