.container {
  padding: 20px 20px 80px;
  @media (min-width: 960px) {
    padding: unset;
  }

  min-width: 0;
}

.title {
  color: var(--color-white);
  font-family: "Manrope-SemiBold";
  font-size: 40px;
  margin-bottom: 37px;

  @media (min-width: 960px) {
    margin-bottom: 32px;
  }
}
