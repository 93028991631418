.collapsible {
  cursor: pointer;
  border-radius: 15px;
  padding: 16px 24px;
  background: rgba(255, 255, 255, 0.2);
  transition: background 0.5s ease-in-out;
  color: var(--color-white);
  position: relative;
  align-items: center;

  .title {
    font-family: "Manrope-SemiBold";
    font-size: 20px;
    @media (min-width: 960px) {
      font-size: 24px;
    }
  }
}

.closeButton {
  position: absolute;
  z-index: 100;
  right: 22px;
  top: 20px;

  @media (min-width: 960px) {
    top: 22px;
  }

  transition: transform 0.3s;

  transform: rotate(45deg);

  &_open {
    transform: rotate(90deg);
  }
}

.content {
  overflow: hidden;
  transition: height 0.3s;

  &__text {
    font-family: "Manrope-Regular";
    font-size: 16px;
    padding: 12px 0 8px;

    white-space:pre-wrap;
  }
}
