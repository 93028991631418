.container {
  padding: 94px 21px 240px;
  @media (min-width: 960px) {
    padding: unset;
  }
}


.title {
  color: var(--color-green);
  font-size: 40px;
  font-family: 'Manrope-SemiBold';

  margin-bottom: 28px;

}

.text{
  width: 100%;
  color: var(--color-white);
  font-size: 20px;
  font-family: 'Manrope-Medium';
  line-height: 160%;

  @media (min-width: 960px) {
    max-width: 587px;
    font-family: 'Manrope-SemiBold';
    font-size: 24px;
  }
}
