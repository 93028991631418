.wrapper{
  width: 100%;

  @media (min-width: 960px) {
    display: flex;
    justify-content: center;
  }
}

.container{
  width: 100%;

  @media (min-width: 960px) {
    width: 952px;
  }

  @media (min-width: 1280px) {
    width: 1196px;
  }

  @media (min-width: 1640px) {
    width: 1440px;
  }

  padding: 20px 20px 240px;

  @media (min-width: 960px) {
    padding: unset;
    align-self: center;
  }
}

.cardContainer{
  width: 100%;
  flex-wrap: wrap;
}

.cardInner{
    height: 100%;
}


.card{
  min-width: 0;
  flex: calc(50% - 4px) 0 0 ;

  @media (min-width: 550px) {
    height: 152px;
    aspect-ratio: unset;
  }

  @media (min-width: 960px) {
    height: 200px;
  }

  @media (min-width: 1640px) {
    height: 240px;
  }
}

.cardUpperContainer{
  display: flex;
  flex-wrap: wrap;

  gap:8px;
  margin-bottom: 8px;

  @media (min-width: 960px) {
    gap:16px;
    margin-bottom: 16px;
  }

  .card{
    aspect-ratio: 1;
    @media (min-width: 960px) {
      flex: calc(25% - 12px) 0 0 ;

      aspect-ratio: unset;
    }
  }
}

.cardBottomContainer{
  display: flex;
  flex-wrap: wrap;

  gap:8px;

  @media (min-width: 960px) {
    gap:16px;
    margin-bottom: 16px;
  }

  .card{
    &_last{
      flex: 100% 0 0 ;
      aspect-ratio: 2;

      @media (min-width: 960px) {
        aspect-ratio: unset;
      }


    }
    @media (min-width: 960px) {
      flex: calc(33.333% - 11px) 0 0 ;
    }

  }

}



.title{
  font-size: 40px;
  font-family: 'Manrope-SemiBold';
  color: var(--color-white);
  margin-bottom: 32px;
}
