.container {
  position: fixed;
  bottom: 20px;
  left: 14px;
  right: 14px;
  z-index: 22;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 66px;
  border-radius: 40px;
  padding-left: 18px;
  padding-right: 18px;

  overflow-y: auto;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  background: linear-gradient(
                  90.38deg,
                  rgba(147, 147, 147, 0.83) 2.72%,
                  rgba(112, 112, 112, 0.77) 96.94%
  );
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 76px;
  min-width: 76px;
  cursor: pointer;
  text-decoration: none;
  opacity: 0.6;

  &.active {
    opacity: 1;
  }
}

.tabTitle {
  color: var(--color-white);
  font-size: 12px;
  font-family: "Manrope-Medium";
  line-height: 18px;

  margin: 0;

  .active & {
    font-family: "Manrope-Bold";
  }
}
