.container {
  display: flex;
  flex-direction: row;

  @media (min-width: 960px) {
    margin-bottom: 65px;
    padding: 16px 24px;
  }

  &__double {
    @media (min-width: 960px) {
      padding: 12px;
    }
  }

  &__program {
    padding: 0;
    margin-bottom: 36px;
    @media (min-width: 960px) {
      margin-bottom: 64px;
    }
  }
}

.titleText {
  font-family: "Manrope-Medium";
  font-size: 24px;
  font-style: normal;
  line-height: 32px;

  margin: 0;

  &__double {
    font-family: "Manrope-Bold";
    font-size: 20px;
    font-style: normal;
    line-height: 28px;

    margin-bottom: 6px;
  }

  &__program {
    font-family: "Manrope-Regular";
    font-size: 24px;
    color: var(--color-greenText);
    line-height: normal;

    margin-bottom: 0;

    @media (min-width: 960px) {
      font-size: 32px;
      margin-bottom: 6px;
    }
  }
}

.descriptionText {
  color: var(--color-textDarkGray);
  font-family: "Manrope-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  margin: 0;

  &__program {
    font-family: "Manrope-Regular";
    font-size: 16px;
    color: var(--color-white);
    max-width: 280px;
  }

  &__option {
    font-size: 16px;
    color: var(--color-white);
  }
}

.iconContainer {
  display: flex;
  margin-right: 24px;

  &__double {
    margin-right: 12px;
  }
}

.icon {
  align-self: flex-start;
}
