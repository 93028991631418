.modal {
  width: auto;
  max-width: 990px;
  padding: 40px;
  height: 638px;
}

.title {
  font-size: 30px;
  font-family: "Manrope-SemiBold";

  margin-bottom: 24px;
}

.description {
  font-family: "Manrope-Medium";
  font-size: 14px;

  margin-bottom: 24px;
}
