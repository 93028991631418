.container {
  width: 100%;
  padding: 30px 20px 250px;
  max-width: 1150px;
  @media (min-width: 960px) {
    padding: 90px 0 0;
  }
}

.title {
  color: var(--color-white);
  font-size: 40px;
  font-family: "Manrope-SemiBold";
  margin-bottom: 40px;
  @media (min-width: 960px) {
    margin-bottom: 4px;
  }
}

.subtitle {
  display: none;
  @media (min-width: 960px) {
    display: block;
  }

  color: rgba(255, 255, 255, 0.4);
  font-size: 24px;
  font-family: "Manrope-SemiBold";
  margin-bottom: 85px;
}

.itemContainer {
  display: flex;
  flex-direction: column;

  gap: 32px;

  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.animatedItem {
  display: flex;
  //flex:1;
}

.item {
  background: rgba(255, 255, 255, 0.2);

  border-radius: 16px;
  overflow: hidden;
  //max-width: 500px;
  flex: 1;
  display: flex;
  flex-direction: column;

  &__preview {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 250px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }

  &__title {
    font-size: 32px;
    color: var(--color-white);
    margin: 15px 30px;
    position: relative;
  }

  &__text {
    font-size: 16px;
    font-family: "Manrope-Medium";
    color: var(--color-white);
    margin: -12px 30px 15px;
    position: relative;
  }

  &__delimiter {
    align-self: stretch;
    background-color: var(--color-white);

    height: 1px;
    min-height: 1px;

    @media (min-width: 960px) {
      width: 1px;
      min-width: 1px;
      margin: 48px 24px 0;

      height: auto;
    }
  }
}

.underline {
  border: rgba(255, 255, 255, 0.15) 1px solid;
  border-top: transparent;
  border-radius: 0 0 16px 16px;
  padding: 20px 30px;
  display: flex;
}

.button {
  align-self: start;
  font-size: 20px;
  font-family: Manrope-SemiBold;
  width: 100%;
  @media (min-width: 960px) {
    width: unset;
    min-width: 220px;
  }
}

.overlay {
  position: absolute;
  height: 100%;
  background: var(--color-green);
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  transition: opacity 0.2s ease-in-out;

  &__title {
    font-size: 32px;
    color: var(--color-black);
    margin: 30px 30px 30px;
    position: relative;
    @media (min-width: 960px) {
      margin: 60px 30px 40px;
    }
  }

  &__text {
    font-size: 16px;
    font-family: "Manrope-Medium";
    color: var(--color-black);
    margin: 4px 30px 15px;
    position: relative;
    flex: 1;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  & .underline {
    border: unset;
  }
}

.disclaimer {
  margin-top: 48px;
  color: var(--color-white);
}
