.mainContainer {
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 20px 105px;

  &__scroll {
    //flex-direction: column;
    //overflow-x: auto;
  }

  @media (min-width: 960px) {
    padding: 0 var(--desktop-horizontal-padding);
    display: flex;
    max-width: 1680px;
    width: 100%;
  }
}

.cardContainer {
  @media (min-width: 960px) {
    transform-origin: top left;
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}

.card {
  &__single {
    @media (min-width: 960px) {
      max-width: 525px;
      width: 100% !important;
    }
  }

  &__triple {
    @media (min-width: 960px) {
      max-width: 340px;
      width: 100% !important;
    }
  }
}
