.arrow_up {
  transform: rotate(90deg);
}

.arrow_down {
  transform: rotate(-90deg);
}

.arrow_right {
  transform: rotate(180deg);
}
