.heading {
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin: 0;
  color: var(--color-textVeryDarkGray);
  margin-bottom: 10px;
}

.content {
  font-family: "Manrope-Light";
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  margin: 0;
  color: var(--color-textVeryDarkGray);
  margin-bottom: 16px;
}

.bottomListContainer {
  padding-top: 16px;
  border-top: 1px solid #000000;
}

.list {
  padding-left: 20px;
  margin-bottom: 24px;
}

.smallText {
  font-family: "Manrope-ExtraLight";
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  margin: 0;
  color: var(--color-textVeryDarkGray);
}
