.button {
  z-index: 2;
  font-size: 18px;
  height: 65px;
  width: 100%;

  margin-top: 24px;
}

.terms {
  font-family: "Manrope-Regular";
  font-size: 12px;
  color: rgba($color: #000000, $alpha: 0.5);

  margin-top: 12px;
  cursor: pointer;

  &__input {
    cursor: default;
  }
}

.error {
  color: var(--color-errorRed);
}
