:root {
  --color-green: #54dbc2;
  --color-darkGreen: #37caaf;
  --color-greenText: #55dcc3;
  --color-gray: #cbcbcb;
  --color-white: #ffffff;
  --color-buttonGray: #8a9199;
  --color-buttonHoverGray: #aab2bb;
  --color-textGray: #878787;
  --color-lightYellow: #fdf9d1;
  --color-textDarkGray: #565656;
  --color-textDarkDarkGray: #424242;
  --color-textVeryDarkGray: #333333;
  --color-borderGray: #adb2b8;
  --color-black: #000;
  --color-errorRed: #ff0000;
}
