.heading {
  font-family: "Manrope-SemiBold";
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  color: var(--color-textVeryDarkGray);
  margin-bottom: 16px;
}

.content {
  font-family: "Manrope-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  color: var(--color-textVeryDarkGray);
  margin-bottom: 24px;
  z-index: 2;
  white-space: pre-wrap;

  &__enlarged {
    font-size: 20px;
    line-height: normal;
  }
}

.bottomListContainer {
  padding-top: 24px;
  border-top: 1px solid #3b9988;
  z-index: 2;

  .buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (min-width: 390px) {
      justify-content: flex-start;
    }
  }

  .button {
    background-color: var(--color-white);
    color: var(--color-black);
    margin-bottom: 16px;
    font-size: 18px;
    border-radius: 15px;

    @media (min-width: 390px) {
      margin-right: 15px;
    }
  }
}
