.container {
  position: relative;
  padding: 160px 24px 0;

  @media (min-width: 960px) {
    padding: 0 0 22px;
  }
}

.title {
  font-family: "Manrope-SemiBold";
  color: var(--color-white);
  font-size: 10vw;
  margin-bottom: 15px;

  @media (min-width: 960px) {
    font-size: 80px;
    line-height: 90px;
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
}

.subTitle {
  font-family: "Manrope-SemiBold";
  color: var(--color-green);
  font-size: 29px;
  line-height: 36px;
  margin-bottom: 16px;

  @media (min-width: 960px) {
    font-size: 24px;
    margin-bottom: 15px;
    white-space: pre-wrap;
  }
}

.description {
  font-family: "Manrope-Light";
  color: var(--color-white);
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 22px;

  @media (min-width: 960px) {
    margin-bottom: 42px;
    white-space: pre-wrap;
  }
}

.bigdescription {
  font-family: "Manrope-SemiBold";
  color: var(--color-white);
  font-size: 4vw;
  margin-top: 24px;

  @media (min-width: 960px) {
    padding-top: 60px;
    font-size: 24px;
    max-width: 640px;
  }
}

.button {
  font-family: "Manrope-SemiBold";
  font-size: 16px;
  padding: 8.5px 25.5px;

  @media (min-width: 960px) {
    font-size: 20px;
    padding: 12px 60px;
    border-radius: 15px;
  }
}

.animatedArrow {
  display: none;
  margin-top: 64px;
  @media (min-width: 960px) {
    display: block;
  }
}
