.block {
  position: relative;
  overflow: hidden;
  z-index: 4;
  display: flex;
  align-items: center;
  height: unset;

  &__alighTop {
    align-items: start;
    @media (min-width: 960px) {
      padding-top: 200px !important;
    }
  }

  @media (min-width: 960px) {
    min-height: 99vh;
    max-width: 1680px;
    width: 100%;
    padding: 0 var(--desktop-horizontal-padding);
  }

  &__fullWidth {
    @media (min-width: 960px) {
      padding: unset;
      max-width: unset;
    }
  }
}
