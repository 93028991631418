.innerWrapper {
  position: fixed;
  z-index: 5;
  top: 42px;
  width: 100%;
  display: flex;
}

.wrapper {
  position: relative;
  max-width: 1680px;
  width: 100%;
  padding: 0 var(--desktop-horizontal-padding);
  display: inline-flex;
  margin: auto;
}

.container {
  position: relative;
  z-index: 3;
  display: inline-flex;
  padding: 10px 24px 10px 8px;
  align-items: center;
  gap: 60px;
  overflow: hidden;
  border-radius: 24px;
  background: var(--color-black);
  min-width: 60px;
  max-width: 100%;
  opacity: 1;

  transition: max-width 0.3s ease-in-out, opacity 0.5s ease-in-out;

  &__hide {
    opacity: 0;
    max-width: 0;
    pointer-events: none;
  }
  .itemList {
    display: inline-flex;
    gap: 16px;
    font-family: "Inter-Medium";
    font-size: 12px;
    color: var(--color-white);

    .item {
      cursor: pointer;
      padding: 10px;
      white-space: nowrap;
      text-decoration: none;
      color: var(--color-white);
      &__active {
        font-family: "Inter-Bold";
      }
    }
  }

  .button {
    padding: 8px 29px;
  }
}

//.wrapper:hover .container{
//    max-width: 100%;
//    opacity: 1;
//}
.logo {
  cursor: pointer;
  height: 36px;
}

.logo_plain {
  margin: 0 52px 0 2px;
  cursor: pointer;
  z-index: 9999;
}

.inside {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  &__text {
    font-family: "Manrope-SemiBold";
    font-size: 16px;
    color: var(--color-white);
    &_black {
      color: var(--color-black);
    }
    margin-right: 148px;
  }
}
.text_component {
  opacity: 1;
  transition: opacity 200ms;
  &__hidden {
    opacity: 0;
  }
}
