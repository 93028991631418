.container {
  display: flex;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border-radius: 0;

  width: 100%;

  font-family: "Manrope-Regular";
  font-style: normal;
  line-height: 120%;

  &::placeholder {
    opacity: 1;
  }

  &__light {
    height: 25.5px;
    border-bottom: 1.5px solid var(--color-borderGray);

    color: var(--color-white);
    font-size: 15px;

    &::placeholder {
      color: var(--color-white);
    }
  }

  &__dark {
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    padding: 16px 0;
    opacity: 0.5;
    margin-bottom: 8px;

    color: var(--color-black);
    font-size: 20px;

    &::placeholder {
      color: var(--color-black);
      opacity: 1;
    }
  }

  &__error {
    color: var(--color-errorRed);
    border-color: var(--color-errorRed);

    &::placeholder {
      color: var(--color-errorRed);
    }
  }
}

input:focus {
  outline: none;
}
