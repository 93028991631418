.container {
  width: 100%;
  padding: 20px 20px 150px;
  margin-bottom: 80px;
  @media (min-width: 960px) {
    margin-bottom: 0;
    padding: unset;
  }

  min-width: 0;

  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1150px;
}

.title {
  color: var(--color-green);
  font-family: Manrope-SemiBold;
  font-size: 36px;
  margin-bottom: 12px;

  @media (min-width: 960px) {
    margin-bottom: 20px;
  }
}

.item {
  font-size: 24px;
  font-family: Manrope-SemiBold;
  background: rgba(255, 255, 255, 0.2);
  border: rgba(255, 255, 255, 0.15) 1px solid;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    flex-direction: row;
  }
}

.left {
  display: flex;
  flex-direction: column;
  padding: 40px 24px 40px 52px;
  flex: 1;
}

.image {
  height: 100%;
}

.serviceCardContainer {
  height: 185px;
  display: flex;
  flex: 1;
  justify-content: space-between;

  &__alone {
    height: 348px;
  }
}

.button {
  align-self: start;
  font-size: 20px;
  font-family: Manrope-SemiBold;
  min-width: 220px;
}
