.progress_bar {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 10;
}

.screen_container {
  overflow: scroll;
}

.screen {
  padding: 30px;
  height: 100vh;
  background-color: lightslategrey;

  &_blue {
    padding: 30px;
    height: 100vh;
    background-color: darkslategrey;
  }

  &_green {
    padding: 30px;
    height: 100vh;
    background-color: #54dbc2;
  }

  &_mobile {
    padding: 0;
    height: 100vh;
    background-color: lightslategrey;
  }
}

.advantageCardsContainer {
  display: flex;
  flex-direction: row;
}

.advantageCard {
  margin-right: 24px;
}

.suitableProgramLeft {
  margin-right: 24px;
}

.suitableProgramsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 29px;
}
