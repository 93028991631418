.backgroundWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(
    90deg,
    #000 21.99%,
    rgba(0, 0, 0, 0.68) 72.92%,
    rgba(0, 0, 0, 0.88) 99.94%
  );
  backdrop-filter: blur(38.65px);
}

.container {
  padding: 20px 20px 240px;
  width: 100%;
  @media (min-width: 960px) {
    padding: unset;
  }
}

.title {
  color: var(--color-white);
  font-size: 40px;
  margin-bottom: 25px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 960px) {
    flex-wrap: nowrap;
    display: flex;
    overflow-y: scroll;

    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }

  gap: 24px;

  .userCard {
    flex: calc(50% - 12px) 0 0;
    min-width: 0;
    //z-index: 5;

    @media (min-width: 960px) {
      flex: none;
    }

    &:nth-child(even) {
      transform: translateY(80px);

      @media (min-width: 960px) {
        transform: unset;
      }
    }
  }
}

.shadow {
  position: absolute;
  height: 500px;
  width: 295px;
  background: linear-gradient(-90deg, black 35.08%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;

  display: none;
  @media (min-width: 960px) {
    display: block;
  }

  &_right {
    right: -100px;
  }

  &_left {
    rotate: 180deg;
    left: -100px;
    transition: opacity 0.3s, transform 0.3s ease-in-out;
  }

  &_hidden {
    opacity: 0;
  }
}
