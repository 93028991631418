.itemTitle {
  font-family: "Manrope-SemiBold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.6;

  margin: 0;
  margin-bottom: 8px;
  max-width: 375px;
}

.itemInfo {
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin: 0;
  max-width: 375px;

  &__link {
    color: var(--color-green);
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 8px;
  padding: 12px 0;

  &__first {
    border: 0;
  }
}

.button {
  display: flex;
  width: 227px;
  height: 66px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: center;
  margin-top: auto;
  position: relative;
  cursor: pointer;

  border-radius: 16px;
  background: linear-gradient(
                  90deg,
                  rgba(33, 33, 33, 0.48) 2.72%,
                  rgba(22, 22, 22, 0.55) 96.94%
  );
  backdrop-filter: blur(6px);
}

.buttonTitle {
  color: var(--color-white);
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin: 0;
}

.buttonIcon {
  position: absolute;
  top: 0;
}

.blur {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 102px;
  background: linear-gradient(180deg, rgba(144, 144, 144, 0) 0%, #9d9d9d 100%);

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 15vh;
}

.container {
  padding: 20px 20px 25vh;
  position: relative;
}

.scrollItemContainer {
  padding-top: 18px;
  overflow: scroll;
  padding-bottom: 140px;
  scroll-behavior: smooth;
}

.downloadButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
