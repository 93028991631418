.container {
  padding: 40px 20px 0;
  @media (min-width: 960px) {
    padding:unset;
  }

}

.title {
  color: var(--color-green);
  font-size: 40px;
  font-family: 'Manrope-SemiBold';

  margin-bottom: 32px;
  @media (min-width: 960px) {
    margin-bottom: 28px;
  }

}

.columnContainer {
  display: flex;

  flex-direction: column;
  gap:24px;

  @media (min-width: 960px) {
    justify-content: space-between;
    gap: 60px;
    flex-direction: row;
  }


}

.column {
  color: var(--color-white);
  font-size: 16px;
  font-family: 'Manrope-Medium';
  line-height: 160%;
}
