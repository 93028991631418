@import '../../styles/common';

.wrapper {
  position: fixed;
  z-index: 5;
  right: 48px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  @media (min-width: 1500px) {
    display: unset;
  }
}

.container {
  width: 43px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.bar_container {
  height: 130px;
  width: 2px;
  background: rgba(255, 255, 255, 0.20);

  &__black {
    background: rgba(0, 0, 0, 0.40);
  }


  .bar {
    width: 2px;
    background: #54DBC2;

    &__black {
      background: var(--color-black);
    }
  }
}

.number {
  @extend .unselectable;
  color: var(--color-white);
  font-family: 'Manrope-SemiBold';
  transition: color 0.25s;
  cursor: pointer;


  &__black {
    color: var(--color-black);
  }

  &_top {
    padding-bottom: 8px;
    font-size: 40px;
  }

  &_bottom {
    padding-top: 8px;
    font-size: 20px;
    opacity: 0.7;
  }
}
