.screenMobile {
  padding: 52px 20px 300px;
  width: 100%;

  .card {
    margin-right: 0;
    margin-bottom: 24px;
  }

  @media (min-width: 640px) {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  @media (min-width: 960px) {
    display: none;
  }
}

.screen {
  width: 100%;
  z-index: 10;

  @media (max-width: 960px) {
    display: none;
  }

  .arrowsContainer {
    display: flex;
    justify-content: flex-end;
    padding-right: 120px;
    margin-bottom: 37px;

    .arrowLeft {
      margin-right: 12px;
    }
  }

  .hide {
    display: none;
  }
}

.cardWrapper {
  display: flex;
  justify-content: center;
}
