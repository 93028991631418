.cardContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 0 var(--desktop-horizontal-padding);
  overflow-x: scroll;

  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.card {
  &__single {
    max-width: 400px;
    width: 100% !important;

    @media (min-width: 1280px) {
      max-width: 488px;
    }

    @media (min-width: 1440px) {
      max-width: 553px;
    }

    @media (min-width: 1920px) {
      max-width: 526px;
    }
  }

  &__triple {
    max-width: 260px;
    width: 100% !important;

    @media (min-width: 1280px) {
      max-width: 318px;
    }

    @media (min-width: 1440px) {
      max-width: 360px;
    }

    @media (min-width: 1920px) {
      max-width: 416px;
    }
  }
}
