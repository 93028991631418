.container{

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.3s, transform 0.3s ease-in-out ;

  &__shown{
    opacity: 1;
    transform: translateY(0);
  }

}

