.mainContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  @media (min-width: 960px) {
    padding: 0 var(--desktop-horizontal-padding);
  }

  gap: 24px;

  transform-origin: top center;

  //@media (min-width: 1800px) {
  //
  //  padding: 0 100px;
  //}

  &__scroll {
    //flex-direction: column;
    //overflow-y: scroll;
    //white-space: nowrap;
    //margin-top: 50px;
    //max-height: 100%;
  }
}

.cardWrapper {
  @media (min-width: 960px) {
    align-self: start;
    //display: flex;
    //flex:1;
    //flex-basis: calc(50% - 24px);
  }

  &_even {
    @media (min-width: 960px) {
      //justify-content: flex-end;
    }
  }
}

.dummyContainer {
  //width: ;
}

.sideGradient {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 291px;
  z-index: 5;
  transition: opacity 0.3s;

  &__left {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 30%,
      rgba(0, 0, 0, 0) 70%
    );
  }

  &__right {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.6) 30%,
      rgba(0, 0, 0, 0) 70%
    );
    right: 0;
  }

  &__hidden {
    z-index: -10;
    opacity: 0;
  }
}
//
//.title{
//  display: none;
//  position: absolute;
//  top:-100px;
//  left: 18%;
//
//
//  @media (min-width: 960px) {
//    display: unset;
//    font-size: 42px;
//    //margin-bottom: 49px;
//    color: var(--color-white);
//    font-family: "Manrope-SemiBold";
//
//  }
//}
