.promosContainer {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.wrapper {
  min-width: 100vw;
  display: flex;
  justify-content: center;
}

.servicesSpecialOrder {
  max-width: 1680px;
  width: 100%;
  padding: 0 var(--desktop-horizontal-padding);

  .contentText {
    font-family: "Manrope-Light";
    font-size: 18px;
    line-height: 28px;
    color: var(--color-white);
    margin: 0;

    margin-bottom: 29px;

    @media (min-width: 960px) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 120px;
      max-width: 480px;
    }
  }

  .button {
    font-size: 16px;
  }

  @media (max-width: 960px) {
    padding: 52px 18px 22px 16px;
    height: calc(100vh - 128px);
  }
}

.headingText {
  font-family: "Manrope-SemiBold";
  font-size: 40px;
  line-height: 120%;
  color: var(--color-white);
  margin: 0;
  margin-bottom: 28px;

  @media (min-width: 960px) {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 24px;
  }
}

.pageMarkerContainer {
  display: flex;

  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 960px) {
    bottom: 80px;
  }
}
