.container {
  display: flex;
  height: 398px;
  position: relative;

  .slide {
    z-index: 4;
    background-color: #38d0b4;
    display: flex;
    flex: 1;
    min-width: 0;
    border-radius: 33px;
    margin-right: 147px;
    overflow: hidden;

    &__number1 {
      transform: translateX(0px);
      box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3);
    }

    &__number2 {
      transform: translateX(73px);
      box-shadow: 1px 0 10px rgba(0, 0, 0, 0.3);
    }

    &__number3 {
      transform: translateX(146px);
      box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.3);
    }
    .imageContainer {
      object-fit: contain;
      height: 100%;
      aspect-ratio: 1/1;
      border-top-right-radius: 33px;
      border-bottom-right-radius: 33px;
      overflow: hidden;

      .image {
        object-fit: cover;
        height: 100%;
        aspect-ratio: 1/1;
        transition: transform 0.8s, opacity 1s;
        opacity: 0.3;

        &__active {
          transform: scale(105%);
          opacity: 1;
        }
      }
    }

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 32px 80px 32px 42px;

      .title {
        color: var(--color-black);
        font-family: "Manrope-Bold";
        font-size: 36px;
        margin-bottom: 24px;

        transform: translateX(-32px);
        opacity: 0.2;

        &__active {
          transform: translateX(0);
          transition: transform 0.8s, opacity 0.5s;
          opacity: 1;
        }
      }

      .description {
        flex: 1;
        color: var(--color-black);
        font-family: "Manrope-Medium";
        font-size: 18px;

        transform: translateX(-42px);
        opacity: 0.2;

        &__active {
          transform: translateX(0);
          transition: transform 0.8s, opacity 0.5s;
          opacity: 1;
        }
      }

      .progress {
        color: rgba(0, 0, 0, 0.4);
        font-family: "Manrope-Medium";
        font-size: 16px;
      }
    }
  }

  .slide__piece {
    position: absolute;
    height: 100%;
    width: 206px;
    color: rgba(255, 255, 255, 0.4);
    font-family: "Manrope-SemiBold";
    font-size: 20px;
    padding-top: 28px;
    cursor: pointer;
  }

  .slide__piece1 {
    border-radius: 30px 0px 0px 30px;
    background: linear-gradient(
      92deg,
      rgba(58, 161, 142, 0.5) 1.29%,
      rgba(19, 148, 124, 0.25) 74.12%
    );
    backdrop-filter: blur(38.650001525878906px);

    left: 0;
    z-index: 2;
    padding-left: 32px;

    &_step1 {
      display: none;
    }

    &_step3 {
      background: linear-gradient(
        92deg,
        rgba(78, 193, 172, 0.5) 1.29%,
        rgba(39, 164, 141, 0.12) 85.94%
      );
      backdrop-filter: blur(38.650001525878906px);
    }
  }

  .slide__piece2 {
    border-radius: 30px 0px 0px 30px;
    background: linear-gradient(
      92deg,
      rgba(58, 161, 142, 0.5) 1.29%,
      rgba(18, 109, 93, 0.22) 74.12%
    );
    backdrop-filter: blur(38.650001525878906px);

    left: 74px;
    z-index: 3;
    padding-left: 32px;

    &_step1 {
      display: none;
    }

    &_step2 {
      display: none;
    }
  }

  .slide__piece3 {
    border-radius: 0px 30px 30px 0px;
    background: linear-gradient(
      270deg,
      rgba(58, 161, 142, 0.5) 0.05%,
      rgba(19, 148, 124, 0.22) 98.25%
    );
    backdrop-filter: blur(38.650001525878906px);

    right: 74px;
    z-index: 3;
    padding-right: 32px;
    text-align: end;

    &_step2 {
      display: none;
    }

    &_step3 {
      display: none;
    }
  }

  .slide__piece4 {
    border-radius: 0px 30px 30px 0px;
    background: linear-gradient(
      270deg,
      rgba(78, 193, 172, 0.5) 0.05%,
      rgba(39, 164, 141, 0.12) 98.25%
    );
    backdrop-filter: blur(38.650001525878906px);

    padding-right: 32px;
    text-align: end;

    right: 0;
    z-index: 2;

    &_step2 {
      width: 348px;
      border-radius: 0px 30px 30px 0px;
      background: linear-gradient(
        270deg,
        rgba(58, 161, 142, 0.5) 0.05%,
        rgba(19, 148, 124, 0.25) 98.25%
      );
      backdrop-filter: blur(38.650001525878906px);
    }

    &_step3 {
      display: none;
    }
  }

  .leftArrow {
    z-index: 5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .rightArrow {
    z-index: 5;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    right: 0;
  }
}

.progressContainer {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 4px;
  height: 30px;
  padding: 0 11px;
  align-items: center;
  border-radius: 10px;

  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.progressItem {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin: 3px;
  background: rgba(138, 145, 153, 0.4);
  backdrop-filter: blur(38.650001525878906px);
  transition: background-color 0.5s;
  cursor: pointer;

  &_active {
    background: var(--color-green);
    margin: 0;
    width: 9px;
    height: 9px;
    border-radius: 9px;
  }
}
