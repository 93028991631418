.container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 156px 0 0;

  @media (min-width: 960px) {
    width: auto;
    height: auto;
    padding: unset;
  }
}

.wrapper {
  min-width: 100vw;
  display: flex;
  justify-content: center;
}

.slide {
  width: 100%;
  height: 100vh;
  padding: 0 24px;

  @media (min-width: 960px) {
    max-width: 1680px;
    padding: 0 var(--desktop-horizontal-padding);

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  font-family: "Manrope-SemiBold";
  color: var(--color-white);
  line-height: 121%;
  font-size: 10vw;
  white-space: pre-wrap;
  padding-bottom: 24px;

  @media (min-width: 960px) {
    padding-bottom: 45px;
    font-size: 80px;
  }
}

.buttonContainer {
  @media (min-width: 960px) {
    padding-bottom: 75px;
  }
}

.button {
  padding: 8.5px 14px;
  font-size: 16px;

  @media (min-width: 960px) {
    padding: 8.75px 17.755px;
    font-size: 15px;
  }
}

.phone {
  text-decoration: none;
  font-family: "Manrope-SemiBold";
  font-size: 20px;
  color: var(--color-white);

  position: absolute;
  left: 24px;
  top: 360px;

  @media (min-width: 550px) {
    top: 460px;
  }

  @media (min-width: 960px) {
    display: none;
  }
}

.animatedArrow {
  display: none;
  @media (min-width: 960px) {
    display: unset;
    padding-bottom: 50px;
  }
}

.pageMarkerContainer {
  display: flex;

  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 960px) {
    bottom: 40px;
  }
}
