.outterContainer {
  position: relative;
}

.modal {
  padding: 40px;
  padding-bottom: 20px;
  background-color: var(--color-green);
  border-radius: 15px;
  position: relative;

  width: 856px;
  max-height: 80vh;
  overflow-y: auto;

  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.modal::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.closeButton {
  position: absolute;
  top: 0;
  right: -53px;
}

.title {
  font-family: "Manrope-Bold";
  font-size: 32px;
  line-height: normal;
  color: var(--color-black);

  margin: 0;
}

.buttonContainer {
  display: flex;

  .button {
    padding: 11px 24px;
    margin-top: auto;
    min-width: 150px;
    white-space: nowrap;
  }

  .inputWarningText {
    font-family: "Manrope-Regular";
    font-size: 12px;
    color: rgba($color: #000000, $alpha: 0.5);

    margin-left: 30px;

    &__error {
      color: var(--color-errorRed);
    }
  }
}

.smallText {
  font-family: "Manrope-Regular";
  font-size: 12px;
  color: rgba($color: #000000, $alpha: 0.5);

  margin: 20px 0 11px 0;
  cursor: pointer;
}
