.mainContainer {
  padding: 0;
  height: 320px;
  width: 100%;

  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-green);
  }

  @media (min-width: 640px) {
    width: calc(50% - 12px);
  }

  @media (min-width: 960px) {
    width: 251px;
    flex-shrink: 0;
  }
}

.button {
  transition: background-color 0.5s;

  @media (min-width: 960px) {
    max-width: 200px;
  }
}

.mainContainer:hover .button {
  background-color: var(--color-black);
}

.titleText {
  font-family: "Manrope-Bold";
  font-size: 16px;
  font-style: normal;
  line-height: 22px;

  margin: 0;
  margin-bottom: 16px;

  @media (max-width: 960px) {
    font-size: 24px;
  }
}

.descriptionText {
  font-family: "Manrope-Medium";
  font-size: 12px;
  font-style: normal;
  line-height: normal;

  margin: 0;
  margin-bottom: auto;

  &__top {
    margin-bottom: 16px;
  }

  @media (max-width: 960px) {
    font-size: 16px;
  }
}

.iconContainer {
  border-bottom: 1px solid var(--color-buttonGray);
  padding: 16px 24px 15px 24px;
  height: 72px;

  @media (max-width: 960px) {
    padding: 16px 24px 6px 24px;
  }
}

.icon {
  height: 40px;

  @media (max-width: 960px) {
    height: unset;
    width: 50px;
  }
}

.contentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
}
