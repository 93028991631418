.container {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: var(--color-green);

  @media (min-width: 960px) {
    height: 294px;
    width: 250px;
  }

  &__blur {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 44.08%,
      rgba(255, 255, 255, 0) 122.15%
    );
    backdrop-filter: blur(10px);
  }
}

.upperContainer {
  display: flex;
  flex-direction: column;
  height: 160px;
  padding: 60px 16px 16px 16px;
  justify-content: flex-end;

  @media (min-width: 960px) {
    height: 197px;
    padding: 99px 24px 14px 16px;
    justify-content: flex-start;
  }
}

.backgroundImage {
  position: absolute;
  z-index: -2;
  height: 160px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-position: top;

  @media (min-width: 960px) {
    height: 197px;
    object-position: unset;
  }
}

.titleText {
  font-family: "Manrope-Regular";
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  color: var(--color-white);

  margin: 0;
  margin-left: 7px;
}

.lowerContainer {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 16px 24px;

  @media (min-width: 960px) {
    padding: 13px 20px 20px 23px;
  }
}

.descriptionText {
  color: var(--color-white);
  font-family: "Manrope-Regular";
  font-size: 14px;
  font-style: normal;
  line-height: normal;

  margin: 0;

  @media (min-width: 960px) {
    font-size: 12px;
  }
}

.gradient {
  position: absolute;
  z-index: -1;
  height: 160px;
  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );

  @media (min-width: 960px) {
    height: 197px;
  }
}
