.container {
  display: flex;
  height: 30px;
  min-width: 0;
  gap: 6px;
  padding: 0 10px;
  border-radius: 10px;
  align-items: center;

  align-self: center;

  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .dot {
    padding: 1px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    opacity: 0.3;
    background: var(--color-white);
    cursor: pointer;

    &_black {
      background: var(--color-black);
    }

    &_active {
      padding: 0;
      opacity: 1;
      background: var(--color-green);
      width: 10px;
      height: 10px;
      border-radius: 10px;

      &_black {
        background: var(--color-black);
      }
    }
  }
}
