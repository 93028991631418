.fixedContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
}

.container {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;

  padding: 20px 0 20px;
  background-color: var(--color-black);

  width: 100%;
}

.topContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 16px;

  &_short {
    margin-bottom: 0;
  }
}

.enterButton {
  font-size: 13px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: var(--color-white);
  font-family: "Manrope-Regular";
  padding: 6px 18px;

  &:hover {
    background-color: var(--color-buttonGray);
  }
}

.gradient {
  height: 40px;
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, transparent 100%);
}

.logo {
  height: 36px;
}
