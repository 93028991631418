.container {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 40px 20px;
  width: 100%;
  max-height: 100%;
  //position: relative;
  bottom: 0;

  border: 1px solid var(--White-hover, #fff);
  border-bottom: none;

  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: linear-gradient(#54dbc2 90%, transparent);
  position: relative;
  padding-bottom: 0;

  &__zeroPadding {
    padding: 0;
  }

  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.bottomGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 259px;
  height: 50%;
  background: linear-gradient(#54dbc2 0%, #00ffd0 100%);
}

.scrollContainer {
  overflow-y: auto;
  z-index: 2;
  padding-top: 38px;
  padding-bottom: 25vh;

  &__zeroPadding {
    padding: 0;
  }

  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.scrollContainer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.title {
  font-family: "Manrope-Bold";
  font-size: 28px;
  font-style: normal;
  line-height: normal;
  margin: 0;
  color: var(--color-black);
  margin-bottom: 24px;
}

.button {
  margin-bottom: 0;
}

.buttonText {
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  margin: 0;
  color: var(--color-white);
}

.closeButton {
  position: absolute;
  right: 8px;
}
