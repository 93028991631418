.textBig {
  font-family: "Manrope-Light";
  font-size: 20px;
  line-height: 120%;
  color: var(--color-textDarkDarkGray);
  margin: 0;
  margin-bottom: 24px;
}

.bottomListContainer {
  padding-top: 15px;
  border-top: 1px solid var(--color-black);
  margin-bottom: 24px;
}

.textMedium {
  font-family: "Manrope-Light";
  font-size: 15px;
  line-height: normal;
  color: var(--color-textDarkDarkGray);
  margin: 0;

  &__heading {
    font-family: "Manrope-Medium";
    margin-bottom: 12px;
    color: var(--color-black);
  }
}

.textSmall {
  font-family: "Inter-ExtraLight";
  font-size: 10px;
  line-height: 15px;
  color: var(--color-black);
  margin: 0;
}

.title {
  font-size: 46px;
  line-height: 53.5px;
  margin-bottom: 24px;
}

.modal {
  padding: 46px 40px 21px 42px;
}
