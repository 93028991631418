.outterContainer {
  display: flex;
  flex-direction: row;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 650px;
  height: 655px;
  margin-right: 12px;

  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(90deg, #999 2.72%, #8c8c8c 96.94%);
}

.contentContainer {
  border-top: 2px solid rgba(0, 0, 0, 0.15);

  &_first {
    border-top: none;
  }
}

.itemContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.scrollableContainer {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 46%, 0.5);
    border-radius: 8px;
  }
}

.header {
  font-family: "Manrope-SemiBold";
  font-size: 32px;
  font-style: normal;
  line-height: normal;
  margin-bottom: 30px;
  color: var(--color-black);
  align-items: center;

  display: flex;
}
