.container {
  display: inline-flex;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 15px;
  background: rgba(226, 226, 226, 0.2);
  color: var(--color-white);
  position: relative;

  font-family: "Manrope-Medium";
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: rgba(253, 249, 209, 0.2);
    color: var(--color-lightYellow);
  }

  .title {
    padding-right: 10px;
  }
}

.container:hover .copy {
  display: none;
}

.copyPressed {
  display: none;
}

.container:hover .copyPressed {
  display: block;
}

.copy_label {
  position: absolute;
  left: calc(100% + 12px);

  color: rgba(253, 249, 209, 0.4);
  font-family: "Manrope-Medium";
  font-size: 16px;
  display: none;
}

.container:hover .copy_label {
  display: block;
}

.copyTable {
  background-color: white;
  opacity: 0;
  position: absolute;
  left: -9999px;
  top: -9999px;
}
