.backgroundWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;

  .backgroundContainer {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    .backgroundImage {
      position: absolute;
      top: 0;
      height: 100vh;
      width: 100%;
      object-fit: cover;

      &_second {
        backdrop-filter: blur(24.3px);
      }

      &_car {
        width: unset;
        right: 0;
        opacity: 0;
        transition: opacity 800ms;

        &_show {
          opacity: 1;
        }
      }
    }
  }
}

.overlay {
  z-index: 1;
  opacity: 0;
  @supports (-webkit-mask-image: url(#mask)) or (mask-image: url(#mask)) {
    opacity: 1;
    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 150px,
      rgba(0, 0, 0, 0) 170px
    );
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 150px,
      rgba(0, 0, 0, 0) 170px
    );
  }
  //-webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 30%);

  &_short {
    opacity: 0;
    @supports (-webkit-mask-image: url(#mask)) or (mask-image: url(#mask)) {
      opacity: 1;
      -webkit-mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 100px,
        rgba(0, 0, 0, 0) 130px
      );
      mask-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1) 100px,
        rgba(0, 0, 0, 0) 130px
      );
    }
  }
}
