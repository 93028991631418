body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --desktop-horizontal-padding: 20px;
}

@media (min-width: 1280px) {
    body {
        --desktop-horizontal-padding: 40px;
    }
}

@media (min-width: 1440px) {
    body {
        --desktop-horizontal-padding: 120px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span,
div,
img,
p {
    box-sizing: border-box;
}

html {
    /* scroll-behavior: smooth; */
}

@media (max-width: 960px) {
    html, body {
        overscroll-behavior-y: none;
        /*position: fixed;*/
    }
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track:hover {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-track:hover {
    width: 4px;
    height: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* width: 4px;
    height: 4px; */
}

::-webkit-scrollbar-corner {
    /*display: none;*/
}

::-webkit-resizer {
    /*display: none;*/
}

/** Mozilla Firefox */
@-moz-document url-prefix() {
    div {
        scrollbar-width: thin;
        scrollbar-color: #888 transparent;
    }

    html {
        scrollbar-width: thin;
        scrollbar-color: #888 transparent;
    }
}
