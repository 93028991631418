.mainContainer {
  padding: 30px 20px 220px;

  @media (min-width: 960px) {
    padding: 0;
    width: 100%;
  }

  .title {
    font-family: "Manrope-SemiBold";
    font-size: 28px;
    color: var(--color-white);

    margin: 0;
    margin-bottom: 24px;

    @media (min-width: 960px) {
      font-family: "Manrope-Medium";
      font-size: 48px;
      color: var(--color-white);

      margin: 0;
      margin-bottom: 40px;
    }
  }

  .cardsContainer {
    @media (min-width: 640px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
    }

    @media (min-width: 960px) {
      display: flex;
      flex-wrap: unset;
      justify-content: center;
      width: 100%;
      transform-origin: top center;
      gap: 24px;
    }

    .card {
      margin-bottom: 24px;

      @media (min-width: 640px) {
        flex: calc(50% - 12px) 0;
        margin-bottom: 0;
      }

      @media (min-width: 960px) {
        flex: unset;
        margin-bottom: 0;
      }
    }
  }
}
