.container {
  height: 519px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(11.25px);
  padding: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s;
  flex-direction: column;
  width: auto;

  cursor: pointer;

  @media (min-width: 960px) {
    width: 458px;
    height: 220px;
    flex-direction: row;
  }

  @media (min-width: 1280px) {
    width: 588px;
  }
  @media (min-width: 1680px) {
    height: 264px;
    width: 705px;
  }

  &_short {
    height: unset;
    cursor: pointer;

    @media (min-width: 960px) {
      height: 175px;
      padding-bottom: 15px;
    }
    @media (min-width: 1680px) {
      height: 210px;
      padding-bottom: 24px;
    }
  }

  .leftColumn {
    flex: 0;
    margin-bottom: 40px;

    &_short {
      margin-bottom: 0;
    }

    @media (min-width: 960px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      flex: 1;
    }

    &__top {
      flex: 0;
      margin-bottom: 20px;

      @media (min-width: 960px) {
        margin-bottom: 0;
      }
    }

    .description {
      gap: 6px;
      display: flex;
      flex-direction: column;
      font-family: "Inter-Regular";
      font-size: 15px;

      @media (min-width: 960px) {
        font-size: 12px;
        max-width: 220px;
        justify-content: flex-start;
        padding-top: 10px;
      }
      @media (min-width: 1280px) {
        font-size: 13px;
        max-width: 280px;
      }
      @media (min-width: 1680px) {
        font-size: 15px;
        max-width: unset;
        padding-top: 20px;
      }
      color: var(--color-black);

      &_short {
        flex-direction: column;
        @media (min-width: 960px) {
          flex-direction: row;
          flex-wrap: wrap;
          max-width: unset;
        }
      }

      .descriptionItem {
        display: flex;
        &_short {
          @media (min-width: 960px) {
            flex: calc(50% - 4px) 0;
          }
        }
      }
    }
  }

  .rightColumn {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .activeButton {
      opacity: 0;
      padding: 6px 32px;
      transition: opacity 0.3s;

      @media (max-width: 960px) {
        width: 128px;
      }

      &_active {
        opacity: 1;
      }
    }
  }

  &_active {
    background-color: var(--color-white);
  }

  &:hover {
    background-color: var(--color-white);

    .button {
      background-color: var(--color-green);
    }

    .activeButton {
      opacity: 1;
    }
  }

  .title {
    font-family: "Inter-SemiBold";
    font-size: 24px;
    color: var(--color-black);

    margin-bottom: 12px;
  }

  .button {
    padding: 4px 12px;
    font-family: "Manrope-SemiBold";
    font-size: 12px;

    cursor: default;
    pointer-events: none;
    transition: background-color 0.5s;
    background-color: var(--color-buttonGray);

    &_active {
      background-color: var(--color-green);
    }
  }

  .backgroundImage {
    position: absolute;
    z-index: -1;

    height: 60%;
    bottom: 0;
    align-self: center;
    right: -125px;

    @media (min-width: 960px) {
      top: 0;
      right: -50px;
      left: unset;

      height: 100%;
    }

    @media (min-width: 1280px) {
      right: 0;
    }

    @media (min-width: 1440px) {
      right: 0;
    }
  }

  .dot {
    margin-right: 8px;
    margin-top: 6px;
    height: 8px;

    @media (min-width: 960px) {
      margin-top: 4px;
    }
    @media (min-width: 1680px) {
      height: 10px;
    }
  }

  .descriptionLineText {
    white-space: initial;
  }
}
