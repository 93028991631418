.mainContainer {
  padding: 30px 20px 320px;
  width: 100%;

  @media (min-width: 960px) {
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-family: "Manrope-SemiBold";
    font-size: 28px;
    line-height: 36px;

    margin: 0 0 32px;

    @media (min-width: 960px) {
      font-family: "Manrope-Medium";
      font-size: 48px;
      line-height: normal;
      margin: 0 0 30px;
      align-self: center;
      padding: 0 var(--desktop-horizontal-padding);
      width: 100%;
      max-width: 1680px;
    }
  }

  .functionsContainer {
    @media (min-width: 960px) {
      display: flex;
    }
  }

  .item {
    margin-bottom: 24px;

    @media (min-width: 960px) {
      max-width: 526px;

      margin-bottom: 16px;
    }
  }
}

.container {
  display: none;

  @media (min-width: 960px) {
    display: flex;
    overflow: hidden;
    position: relative;
  }
}

.wrapper {
  min-width: 100vw;
  display: flex;
  justify-content: center;
}

.slide {
  width: 100%;
  height: 100%;
  padding: 0 24px;

  @media (min-width: 960px) {
    max-width: 1680px;
    padding: 0 var(--desktop-horizontal-padding) 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.leftContainer {
  width: 50%;
}

.rightContainer {
  width: 50%;
}

.pageMarkerWrapper {
  display: none;
  @media (min-width: 960px) {
    display: flex;

    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    display: none;
  }
}
